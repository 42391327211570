import React from "react";

import styled from 'styled-components'

import { useStaticQuery, graphql } from "gatsby"

import Img from 'gatsby-image'
import SEO from "../components/seo"
import Layout from "../components/layout"
import TextBlock from '../components/textBlock'
import Hero from '../components/hero'
import SectionTitle from '../components/sectionTitle'

const WeddingDayPage = () => {

    const data = useStaticQuery(graphql`
        query {
            hero: file(relativePath: { eq: "Engagement-57.jpg" }) {
                childImageSharp {
                fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
                }
                }
            },
        timeline1: file(relativePath: { eq: "stmary.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        timeline2: file(relativePath: { eq: "doubletree-wedding-day.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
    }
    `)

  const HeaderContainer = styled.div ({
    backgroundColor: '#fff',
    padding: '24px',
    boxSizing: 'border-box',
    width: 'calc(100% - 32px)',
    display: 'block',
    margin: 'auto',
    'box-shadow': '0px 0px 12px 4px rgba(0, 0, 0, 0.2)',
    borderRadius: '16px',
    marginTop: "-24px",
    position: 'relative',
    'z-index': '10',
  })

  const TimelineContainer = styled.div ({
    width: 'calc(100% - 32px)',
    display: 'block',
    margin: 'auto',
    position: 'relative',
    '&::before' : {
        content: '" "',
        position: 'absolute',
        width: '2px',
        height: '100%',
        left: '0px',
        top: '6px',
        background: '#e3e3e3'
    }
  })

  const TimelineSection = styled.div({
    position: 'relative',
    display: 'block',
    padding: '0px 0px 24px 24px',
    '&::before' : {
        content: '" "',
        position: 'absolute',
        width: '8px',
        height: '8px',
        left: '-3px',
        borderRadius: '100%',
        top: '6px',
        background: '#E9BA00'
    }
  })

  const TimelineDate = styled.span`
    font-weight: bold;
    color: #666;
    font-family: 'Lato', arial, sans-serif;
    font-size: 16px;
    display: block;
  `

  const TimelineTitle = styled.span`
    font-weight: 400;
    color: #666;
    font-family: 'Source Serif Pro', serif;
    font-size: 14px;
    display: block;
    margin-top: 8px;
  `

  const TimelineImage = styled(Img)`
    border-radius: 16px;
    width: calc( 100%);
    margin-top: 24px;
    height: auto;
  `
    
  return ( 
    <div>
      <Layout>
      <SEO title="Wedding Day | Reinhart Mitton" />
      <Hero file={data.hero.childImageSharp.fluid} style={{marginTop: '50px'}}/>
        <HeaderContainer>
            <TextBlock
                title="The Big Day"
                subTitle="Schedule of Events"
            >
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam urna metus, finibus et convallis et, congue in lacus. Vivamus et augue luctus, tristique eros et, facilisis neque. In porta nibh a commodo volutpat. */}
              <span style={{color: '#666', fontSize: '14px', display: 'block', paddingTop: '12px', marginTop: '12px', borderTop: '1px solid #BBB', width: '100%'}}>*Please note the reception will be adults only.</span>
            </TextBlock>
        </HeaderContainer>
        <SectionTitle title="Schedule" style={{ margin: "24px 16px", display: "block" }}/>
        <TimelineContainer>
            <TimelineSection>
                    <TimelineDate>
                        The Ceremony
                    </TimelineDate>
                    <TimelineTitle>
                      Saturday, May 29, 2021 - 2:00 P.M.<br /><br />
                      <strong>St. Mary of the Cataract</strong><br />
                      237 4th St.<br />
                      Niagara Falls, NY 14303
                    </TimelineTitle>
                    <TimelineImage fluid={data.timeline1.childImageSharp.fluid} />
            </TimelineSection>
            <TimelineSection>
                    <TimelineDate>
                        The Reception
                    </TimelineDate>
                    <TimelineTitle>
                      Saturday, May 29, 2021 - 5:00 P.M.<br /><br />
                      <strong>Double Tree by Hilton</strong><br />
                      401 Buffalo Avenue<br />
                      Niagara Falls, NY 14303
                    </TimelineTitle>
                    <TimelineImage fluid={data.timeline2.childImageSharp.fluid} />
            </TimelineSection>
        </TimelineContainer>
      </Layout>
    </div>  
  )
}

export default WeddingDayPage